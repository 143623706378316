import React from "react";
import i18n from "../../services/translation/i18next"
import PortfolioPageComponent from '../../components/PortfolioPageComponent/PortfolioPageComponent'

const Portfolio = ({ pageContext }) => {
  i18n.t("portfolioPage.project.buildup", { returnObjects: true })
  return (
    <PortfolioPageComponent  data={pageContext.data} />
  );
};

export default Portfolio;
